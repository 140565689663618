import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import {DeliveryMethod, DeliveryMethodService, OrderDetailsView, OrderManagementService, PaymentMethodDBModel, PaymentMethodService, SalesChannel, SalesChannelService, PaidStatusEnum, EditOrderService} from "../../../api";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { DialogHelper, DialogWidth, ProductTypeEnum } from "@tymes4-shared";
import { ApplicationModuleHelperService } from "../../../services/application-module.service";
import { OrderReferenceDialogComponent } from "../../../dialogs/order-reference-dialog/order-reference-dialog.component";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'app-order-detail-details',
    templateUrl: './order-detail-details.component.html',
    styleUrls: ['./order-detail-details.component.scss']
})
export class OrderDetailDetailsComponent implements OnInit {
  @Input() orderDetails: OrderDetailsView;
  @Input() isInEditMode: boolean = false;
  @ViewChild('deliveryMethodDropdown')  deliveryMethodDropdown: ElementRef;
  @ViewChild('paymentMethodDropdown')  paymentMethodDropdown: ElementRef;
  private customerUrl: string = 'relations/customers/details/';
  public totalTickets: number;
  public isIbaActive: boolean = false;
  public deliveryMethods: DeliveryMethod[];
  public salesChannels: SalesChannel[];
  public paymentMethods: PaymentMethodDBModel[];

  constructor(
    private router: Router,
    private translate: TranslateService,
    private elementRef: ElementRef,
    private applicationModuleHelperService: ApplicationModuleHelperService,
    private deliveryMethodService: DeliveryMethodService,
    private salesChannelService: SalesChannelService,
    private paymentMethodService: PaymentMethodService,
    private orderManagementService: OrderManagementService,
    private editOrderService: EditOrderService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.deliveryMethodService.listAllDeliveryMethods().subscribe(methods => {
      this.deliveryMethods = methods;
    });
    this.salesChannelService.listAllSalesChannels().subscribe(channels => {
      this.salesChannels = channels;
    });
    this.paymentMethodService.searchPaymentMethod("", 0, 999).subscribe(methods => {
      this.paymentMethods = methods.Records.filter(method => method.Active);
    });

    this.applicationModuleHelperService.getActiveModules().subscribe((activeModules) => {
      if (activeModules !== null) {
        this.isIbaActive = activeModules.some((module) => module.toUpperCase() == 'IBA')
      }
    });

    this.calculateTicketCount(this.orderDetails);
  }

  onSalesChannelChange(e) {
    this.orderDetails.SalesChannelId = e.Id;
    this.orderDetails.SalesChannelName = e.Name
    this.editOrderService.updateOrderDetails(this.orderDetails).subscribe();
  }

  onDeliveryMethodChange(e) {
    this.orderDetails.DeliveryMethodId = e.Id;
    this.orderDetails.DeliveryMethodName = e.Name;
    this.editOrderService.updateOrderDetails(this.orderDetails).subscribe();
  }

  onPaymentMethodChange(e) {
    this.orderDetails.UsedPaymentMethods = e.Name;
    // TODO: update the order based on the new PaymentMethodId
  }

  linkToCustomer(cId) {
    const link = this.customerUrl + cId;
    this.router.navigate([link]);
    return false;
  }

  getPrimaryPaymentMethod() {
    if (this.orderDetails) {
      if (this.orderDetails.PayByInvoice) {
        return this.translate.instant('T4.ORDERHEADER.PAYMENTMETHOD.INVOICE');
      } else {
        return this.orderDetails.UsedPaymentMethods ? this.orderDetails.UsedPaymentMethods.split(',')[0] : this.translate.instant('T4.ORDERHEADER.PAYMENTMETHOD.DIRECT');
      }
    } else {
      return '';
    }
  }

  calculateTicketCount(orderDetails: any) {
    this.totalTickets = 0;

    if (!orderDetails) {
      return;
    }

    const linesToCount = orderDetails.SecondaryOrderLines ?? orderDetails.OrderLines;
    this.totalTickets = linesToCount.filter(ol => ol.ProductType == ProductTypeEnum.Ticket).length;
  }

  getBoundingClientRect() {
    if (
      this.deliveryMethodDropdown != undefined && this.deliveryMethodDropdown.nativeElement.scrollHeight <= 0 &&
      this.paymentMethodDropdown != undefined && this.paymentMethodDropdown.nativeElement.scrollHeight <= 0
    ) {
      const element = this.elementRef.nativeElement.getElementsByClassName('info-highlight');
      let result = (element[0].offsetTop * 2);
      for (let i = 0; i < element.length; i++) {
        if (i == element.length - 1) {
          element[i].style.height = (result) + 'px';
          continue;
        }

        result += element[i].scrollHeight;
      }
    }
  }

  transform(value: string, limit: number): string {
    if (!value) return '';

    return value.length > limit
      ? value.substring(0, limit) + '...'
      : value;
  }

  clickAction(actionType: string) {
    if (actionType == 'reference') {
      const title = this.translate.instant('SALES-ENTITY-MODIFICATION.DIALOG.TITLE.INPUT-REFERNCE' );

      const options = DialogHelper.GetDialogOptions(DialogWidth.lg, { title: title, data: { reference: this.orderDetails.Reference } });
      let dialogRef: MatDialogRef<any> = this.dialog.open(OrderReferenceDialogComponent, options);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.orderDetails.Reference = result;
          this.editOrderService.updateOrderDetails(this.orderDetails).subscribe();
        }
      });
    }
  }

  public readonly PaidStatusEnum = PaidStatusEnum;
}
